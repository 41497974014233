<template>
  <div class="info">
    <van-popup v-model="control.communityPopup" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="selectList.allHouseTree" @confirm="communitySelect($event)"
        @cancel="control.communityPopup = false" />
    </van-popup>

    <!-- <van-popup v-model="control.subareaPopup" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="selectList.subareaList" @confirm="subareaSelect($event)"
        @cancel="control.subareaPopup = false" />
    </van-popup> -->
    <van-popup v-model="control.subareaPopup" position="bottom">
      <house-select @houseConfirm="closeHouseSelect"></house-select>
    </van-popup>
    <van-popup v-model="control.houseTreeCascader" position="bottom">
      <van-cascader title="请选择" value="value" :field-names="{ text: 'label', value: 'value', children: 'children' }"
        :options="selectList.houseTree" active-color="#1989fa" @close="control.houseTreeCascader = false"
        @finish="control.houseTreeCascader = false" @change="houseCascaderChange($event)" />
    </van-popup>
    <van-popup v-model="control.relationship" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="selectList.relationshipList"
        @confirm="relationshipConfirm($event)" @cancel="control.relationship = false" />
    </van-popup>
    <van-popup v-model="control.useType" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="selectList.useTypeList" @confirm="useTypeConfirm($event)"
        @cancel="control.useType = false" />
    </van-popup>
    <van-popup position="bottom" v-model="control.live">
      <van-picker show-toolbar value-key="label"
        :columns="[{ value: 1, label: '常住' }, { value: 2, label: '偶尔居住' }, { value: 0, label: '不住' }]"
        @confirm="liveConfirm($event)" @cancel="control.live = false" />
    </van-popup>

    <van-popup position="bottom" v-model="control.isManageShow">
      <van-picker show-toolbar value-key="label" :columns="[{ label: '是', value: 1 }, { label: '否', value: 0 }]"
        @confirm="manageConfirm($event)" @cancel="control.isManageShow = false" />
    </van-popup>

    <van-cell-group style="margin-bottom: 70px">
      <van-cell class="custom">
        <template #title>
          <span class="custom-title">房屋信息</span>
        </template>
      </van-cell>
      <van-field v-if="!!submitData.id" :readonly="true" v-model="submitData.gridName || '未知'" name="所属网格" label="所属网格"
        placeholder="未知" />
      <van-cell v-if="!!submitData.id" :readonly="true"
        :value="submitData.gridUserName ? (submitData.gridUserName + ' ' + submitData.gridUserMobile || '') : '未知'"
        name="网格长" title="网格长">
        <template #right-icon v-if="submitData.gridUserMobile">
          <a :href="'tel:' + submitData.gridUserMobile" @click.stop="" style="margin-left: 8px"><img
              src="@/assets/img/phone2.png" alt="" height="14" /></a>
        </template>
      </van-cell>
      <van-cell v-if="control.disabled" name="房主" title="房主"
        :value="submitData.householdName ? (submitData.householdName + ' ' + submitData.householdMobile || '') : '无户主'">
        <template #right-icon v-if="submitData.householdMobile">
          <a :href="'tel:' + submitData.householdMobile" @click.stop="" style="margin-left: 8px"><img
              src="@/assets/img/phone2.png" alt="" height="14" /></a>
        </template>
      </van-cell>

      <van-cell v-if="control.isCommunityShow" readonly clickable name="居住信息" :value="selectMsg.subareaName" required
        :value-class="{ 'value-common': selectMsg.subareaName == '请选择' }" :is-link="!control.disabled" title="居住信息"
        placeholder="点击选择社区" @click="subareaShow" />

      <van-cell v-else readonly clickable name="居住信息" :value="selectMsg.subareaName" required
        :value-class="{ 'value-common': selectMsg.subareaName == '请选择' }" :is-link="!control.disabled" title="居住信息"
        placeholder="点击选择居住信息" @click="subareaShow" />



      <!-- <van-cell
          v-else
          readonly
          clickable
          name="小区名称"
          :value="selectMsg.subareaName"
          :value-class="{'value-common':selectMsg.subareaName=='请选择'}"
          :is-link="!control.disabled"
          title="小区名称"
          placeholder="点击选择小区"
          @click="subareaShow"
      /> -->


      <!-- <van-cell
          readonly
          clickable
          name="居住房屋"
          :value="selectMsg.fullName"
          :is-link="!control.disabled"
          :value-class="{'value-common':selectMsg.fullName=='请选择'}"
          title="居住房屋"
          placeholder="点击选择居住房屋"
          @click="houseTreeShow"
      /> -->

      <van-cell readonly clickable name="与房主关系" :value="selectMsg.relationshipStr" :is-link="!control.disabled" required
        :value-class="{ 'value-common': selectMsg.relationshipStr == '请选择' }" title="与房主关系" placeholder="点击选择与房主关系"
        @click="relationshipShow" />

      <van-cell readonly clickable name="房屋属性" :value="selectMsg.useTypeStr" :is-link="!control.disabled" required
        :value-class="{ 'value-common': selectMsg.useTypeStr == '请选择' }" title="房屋属性" placeholder="点击选择房屋属性"
        @click="useTypeShow" />


      <van-cell title="是否居住" :value="selectMsg.live || '请选择'" :is-link="!control.disabled" required
        @click="control.live = true;" />

      <van-cell title="是否默认管理" :value="selectMsg.isManage || '请选择'" :is-link="!control.disabled" required
        @click="control.isManageShow = true;" />

      <van-cell title="备注" :border="false" class="cellClass" />
      <van-field clearable input-align="left" clear-trigger="always" v-model="submitData.remark" rows="4"
        class="info-title" :border="false" autosize type="textarea" placeholder="备注" :readonly="control.disabled" />
    </van-cell-group>

    <van-row gutter="10" class="btns">
      <van-col span="12">
        <van-button round block type="info" native-type="submit" plain @click="submit(false)"
          v-if="!control.disabled && !submitData.id">确认添加
        </van-button>
        <van-button round block type="info" plain @click="submit(false)" v-if="!control.disabled && submitData.id">确认保存
        </van-button>
      </van-col>
      <van-col span="12">
        <van-button round block type="info" native-type="submit" @click="submit(true)"
          v-if="!control.disabled && !submitData.id">添加并返回
        </van-button>
        <van-button round block type="info" @click="submit(true)" v-if="!control.disabled && submitData.id">保存并返回
        </van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { beforeFileUpload, formatterDate } from "@/utils/utils"
import { getVirtualDict, getbelongSubArea } from '@/utils/common'
import HouseSelect from '@/components/houseSelect/index'

export default {
  data() {
    return {
      visible: true,
      loading: false,
      street: 0,
      initOrgId: 0,
      control: {
        disabled: false,
        subareaPopup: false,
        houseTreeCascader: false,
        relationship: false,
        useType: false,
        live: false,
        isCommunityShow: false,
        isManageShow: false
      },
      selectMsg: {
        registryTypeStr: "未知",
        subareaName: "请选择",
        fullName: "请选择",
        relationshipStr: "未知",
        useTypeStr: "请选择",
        communityName: '请选择',
        isManage: '请选择',
        live: '常住'
      },
      submitData: {
        id: 0,
        userId: 0,
        community: '',
        subarea: '',
        houseId: '',
        relationship: 0,
        useType: 0,
        nowLive: 1,
        remark: '',
        houses: [],
        moveOut: 0,
        status: 0,
        live: 1,
        gridName: '',
        gridUserName: '',
        gridUserMobile: '',
        householdName: '',
        householdMobile: '',
        isManage: '',
        orgId: ""
      },
      selectList: {
        subareaList: [],
        houseTree: [],
        relationshipList: [],
        useTypeList: [],
        allHouseTree: []
      }
    }
  },
  components: {
    HouseSelect
  },
  methods: {
    closeHouseSelect(val,val2) {
      if (this.$globalData.userInfo.depth == 5){
        this.submitData.community = val2[0].parentId
      }else if (this.$globalData.userInfo.depth == 4){
        this.submitData.community = val2[0].value
      }
      this.control.subareaPopup = false
      if (!val2[val2.length - 1].children || val2[val2.length - 1].children.length == 0) {
        this.submitData.orgId = val.orgId
        this.submitData.houseId = val.houseId
        this.selectMsg.subareaName=" "
        if(!val2){
          val2=[]
        }
        val2.forEach((item)=>{
          this.selectMsg.subareaName+=item.label+"  "
        })
        if (val2[val2.length - 1].parameters.houseType) {
          this.submitData.useType = val2[val2.length - 1].parameters.houseType
          this.selectMsg.useTypeStr = this.selectList.useTypeList.filter(i => i.value == this.submitData.useType)[0].label
        }
      }
    },
    // 初始化
    init() {
      this.street = this.$globalData.userInfo.street
      this.initOrgId = this.$globalData.userInfo.orgId
      this.submitData.userId = this.$route.query.userId || 0
      if (this.street == this.initOrgId) {
        this.control.isCommunityShow = true
      }
      this.houseSelect();
      if (this.$route.query.data) {
        let data = JSON.parse(decodeURIComponent(this.$route.query.data))
        this.getInfo(data)
      }
    },
    //房屋信息select加载
    houseSelect() {
      //所有小区
      let that = this
      getbelongSubArea(this.$globalData.userInfo.orgId, function (subAreaList) {
        that.selectList.subareaList = subAreaList
      })
      //居住房屋
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/liveWhichTree`),
        method: 'get',
        params: this.$http.adornParams({
          communityId: this.$globalData.userInfo.orgId
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.selectList.allHouseTree = data.liveWhichTree
        }
      })
      //与户主关系
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/relationships`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data.code == 0) {
          data.relationships.map((r) => {
            r.value = r.value + ""
          })
          this.selectList.relationshipList = data.relationships
        }
      })

      //房屋属性
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/useType`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        if (data.code == 0) {

          data.useTypes.map((u) => {
            u.value = u.value + ""
          })
          this.selectList.useTypeList = data.useTypes
          this.setHouse()
        }
      })
    },
    setHouse () {
      if (this.$route.query.code) {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/building/house/infoByCode`),
          method: 'get',
          params: this.$http.adornParams({
            'code': this.$route.query.code
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.submitData.orgId = data.house.subarea
            this.submitData.houseId = data.house.id
            this.submitData.useType = data.house.useType
            this.submitData.community = data.house.community
            this.selectMsg.useTypeStr = this.selectList.useTypeList.filter(i => i.value == this.submitData.useType)[0].label
            this.selectMsg.subareaName = data.house.subareaName + data.house.fullName
            if (this.$globalData.userInfo.depth == 4) this.selectMsg.subareaName.unshift(data.house.communityName)
          } else {
            this.$message.error(data.msg)
          }
        })
      }
    },
    // 获取详情
    getInfo(data) {
      this.submitData.id = data.id || 0
      this.submitData.houseId = data.houseId || 0
      this.submitData.gridName = data.gridName
      this.submitData.gridUserName = data.gridUserName
      this.submitData.gridUserMobile = data.gridUserMobile
      this.submitData.householdName = data.householdName
      this.submitData.householdMobile = data.householdMobile
      this.selectMsg.communityName = data.communityName
      this.submitData.community = data.community
      this.submitData.subarea = data.subarea;
      this.selectMsg.subareaName = data.subareaName + data.fullName;
      this.selectMsg.fullName = data.fullName
      this.selectMsg.relationshipStr = data.relationshipStr
      this.submitData.relationship = data.relationship
      this.selectMsg.useTypeStr = data.useTypeStr
      this.submitData.useType = data.useType
      this.selectMsg.live = data.nowLive == 1 ? '常住' : data.nowLive == 2 ? '偶尔居住' : '不住'
      this.submitData.live = data.nowLive
      this.submitData.remark = data.remark;
      this.submitData.status = data.status
      this.selectMsg.isManage = data.manage == 1 ? '是' : '否'
      this.submitData.isManage = data.manage
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/trouble/delete'),
          method: 'post',
          params: this.$http.adornParams({
            ids: parseInt(this.submitData.id)
          })
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    communityShow() {
      if (!this.control.disabled) {
        this.control.communityPopup = true
      }
    },
    communitySelect(e) {
      this.submitData.community = e.value
      this.selectMsg.communityName = e.label
      // 清空小区
      this.selectMsg.subareaName = "请选择"
      this.submitData.subarea = ""
      //清空居住房屋
      this.selectMsg.fullName = "请选择"
      this.submitData.houseId = ""
      this.control.subareaPopup = false
    },
    subareaShow() {
      if (!this.control.disabled) {
        this.control.subareaPopup = true
      }
    },
    subareaSelect(e) {
      this.submitData.subarea = e.value
      this.selectMsg.subareaName = e.label
      //清空居住房屋
      this.selectMsg.fullName = "请选择"
      this.submitData.houseId = ""
      this.control.subareaPopup = false
    },
    //显示居住信息
    houseTreeShow() {
      if (!this.control.disabled) {
        let subarea = this.submitData.subarea
        let allHouseTree = this.selectList.allHouseTree
        if (!!subarea) {
          allHouseTree.map((t) => {
            if (t.value == subarea) {
              this.selectList.houseTree = t.children
              return;
            }
          })
          this.control.houseTreeCascader = true
        } else {
          this.$toast.fail("请先选择小区")
        }
      }
    },
    //居住信息改变
    houseCascaderChange(e) {
      let a = (!!(e.selectedOptions[(e.selectedOptions.length - 1)].children))
      console.log(a)
      if (!a) {
        let houseId = null;
        if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
          houseId = e.selectedOptions[(e.selectedOptions.length - 1)].parameter;
        } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
          houseId = e.selectedOptions[(e.selectedOptions.length - 1)].value;
        } else {
          return
        }

        if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 2) {
          this.submitData.houseId = houseId;
          let org = e.selectedOptions[(e.selectedOptions.length - 1)].label;
          let fullName = org.substring(0, org.length - 1)
          this.selectMsg.fullName = fullName
        } else if (e.selectedOptions[(e.selectedOptions.length - 1)].depth == 4) {
          this.submitData.houseId = houseId
          this.selectMsg.fullName = e.selectedOptions[(e.selectedOptions.length - 1)].parameter
        }
      }
    },
    relationshipShow() {
      if (!this.control.disabled) {
        this.control.relationship = true
      }
    },
    relationshipConfirm(e) {
      this.submitData.relationship = e.value
      this.selectMsg.relationshipStr = e.label
      this.control.relationship = false
    },
    useTypeShow() {
      if (!this.control.disabled) {
        this.control.useType = true
      }
    },
    useTypeConfirm(e) {
      this.submitData.useType = e.value
      this.selectMsg.useTypeStr = e.label
      this.control.useType = false
    },
    liveConfirm(e) {
      this.submitData.live = e.value
      this.selectMsg.live = e.label
      this.control.live = false
    },
    manageConfirm(e) {
      this.submitData.isManage = e.value
      this.selectMsg.isManage = e.label
      this.control.isManageShow = false
    },
    // 提交
    submit(isReturn) {
      let check = true
      if (!this.submitData.houseId) {
        check = false
        this.$toast.fail("居住房屋不能为空")
      }
      if (!this.submitData.relationship && this.submitData.relationship !== 0) {
        check = false
        this.$toast.fail("与房主关系不能为空")
      }
      if (!this.submitData.useType && this.submitData.useType !== 0) {
        check = false
        this.$toast.fail("房屋属性不能为空")
      }
      if (!this.submitData.live && this.submitData.live !== 0) {
        check = false
        this.$toast.fail("是否居住不能为空")
      }
      if (!this.submitData.isManage && this.submitData.isManage !== 0) {
        check = false
        this.$toast.fail("是否默认管理不能为空")
      }
      let resHouses = [{
        id: this.submitData.id,
        userId: this.submitData.userId,
        subarea: this.submitData.subarea,
        houseId: this.submitData.houseId,
        relationship: this.submitData.relationship,
        useType: this.submitData.useType,
        nowLive: this.submitData.live,
        remark: this.submitData.remark,
        moveOut: this.submitData.moveOut,
        status: this.submitData.status,
        manage: this.submitData.isManage,
        community: this.submitData.community
      }]
      if (check) {
        this.loading = true
        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/res/houseRes/saveOrUpdate`),
          method: 'post',
          data: this.$http.adornData({
            resHouses: JSON.stringify(resHouses)
          })
        }).then(({ data }) => {
          this.$toast.clear()
          if (data.code == 0) {
            this.$toast.success({
              message: '成功',
              duration: 1500,
              onClose: () => {
                if (isReturn) {
                  sessionStorage.setItem('changeType', 2)
                  this.$router.go(-1)
                }
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
          this.loading = false
        })
      }
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
  },
  created() {
    this.init()
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style  scoped>
.left>>>.van-field__control {
  text-align: left !important;
}

/* .left >>> .van-icon {
  position: absolute;
  top: 0;
  right: 0;
} */
</style>
<style lang="scss" scoped>
::v-deep .van-collapse-item__content {
  padding: 0;
}

.btns {
  position: fixed;
  bottom: 20px;
}
</style>
